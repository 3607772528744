import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import '../static/Navbar.css'
import transparent from '../static/transparent_logo.png'
import icon from '../static/icon.jpg'

import Link from '@mui/material/Link';
import { Outlet} from "react-router-dom"
import useAuth from "../utils/authentication";


const pages = [{label:'Календар', link:"/"}, {label:'Распореди', link:"/rasporedi/"}, {label:"Магацин", link:"/magacin/"}, {label:"Дневник", link:"/dnevnik/"}];
const settings = ['Профиил', 'Одлогирај се'];

function Navbar({ isActive }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    let homeActive = ""
    let rasporedActive = ""
    let statsActive = ""
    let warehouseActive = ""
    let dailyOperationsActive = ""


    if (isActive === "Calendar") {
        homeActive += ' active';
    }
    else if (isActive === "Schedules") {
        rasporedActive += ' active';
    }
    else if (isActive === "Stats") {
        statsActive += ' active';
    }
    else if (isActive === "Warehouse") {
        warehouseActive += ' active';
    }

    else if (isActive === "DailyOperations") {
        dailyOperationsActive += ' active';
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useAuth()

    return (
        <>
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box component="img" src={transparent} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} className='logo' />


                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                                    <Link textAlign="center" href={page.link}>{page.label}</Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box component="img" src={transparent} sx={{ display: { xs: 'block', md: 'none' }, mr: 1}} className='logo' />
                    

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Link
                            href="/"
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 200,

                                color: '#FFF',
                                textDecoration: 'none',
                            }}
                            className={homeActive}
                        >
                            Календар
                        </Link>

                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/rasporedi/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 200,

                                color: '#FFF',
                                textDecoration: 'none',
                            }}
                            className={rasporedActive}
                        >
                            Распореди
                        </Typography>
                        
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/magacin/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 200,

                                color: '#FFF',
                                textDecoration: 'none',
                            }}
                            className={warehouseActive}
                        >
                            Магацин
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/dnevnik/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 200,

                                color: '#FFF',
                                textDecoration: 'none',
                            }}
                            className={dailyOperationsActive}
                        >
                            Дневник
                        </Typography>

                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar src={icon} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >

                            <MenuItem onClick={handleCloseUserMenu} href="/statistika/">
                                <Link textAlign="center" href="/profile/">Мој Профиил</Link>
                            </MenuItem>

                            <MenuItem onClick={handleCloseUserMenu}>
                                <Link textAlign="center" href="/logout/">Одлогирај се</Link>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Outlet/>
        </>
    );
}
export default Navbar;