// CustomModal.js

import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import axios from 'axios'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function ProductEditing({ open, handleClose, productId, refreshData }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [productName, setProductName] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [supplier, setSupplier] = useState('');
    const [numberDoc, setNumberDoc] = useState('');
    const [quantity, setQuantity] = useState('');
    const [date, setDate] = useState(new Date());


    // Update state handlers
    const handleChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'productName':
                setProductName(value);
                break;
            case 'manufacturer':
                setManufacturer(value);
                break;
            case 'supplier':
                setSupplier(value);
                break;
            case 'numberDoc':
                setNumberDoc(value);
                break;
            case 'quantity':
                setQuantity(value);
                break;
            case 'date':
                setDate(value);
                break;

            default:
                break;
        }
    };

    const handleDateChange = (value) => {
        console.log(value)
        setDate(value)

    }


    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        axios.get(baseUrl + `/warehouse/products/${productId}`, { headers: headers })
            .then(function (response) {
                console.log(response.data)
                setProductName(response.data[0].name)
                setManufacturer(response.data[0].manufacturer)
                setSupplier(response.data[0].invoice_number.company_name)
                setNumberDoc(response.data[0].invoice_number.invoice_number)
                setQuantity(response.data[0].quantity)
                setDate(new Date(response.data[0].expiary_date))


            })

    }, [])

    const addNewProduct = () => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };

        let exp_date = new Date(date)

        let data = {
            name: productName,
            manufacturer: manufacturer,
            supplier: supplier,
            numberDoc: numberDoc,
            quantity: quantity,
            expiary_date: `${exp_date.getFullYear()}-${exp_date.getMonth() + 1}-${exp_date.getDay()}`,

        }
        axios.patch(baseUrl + `/warehouse/products/${productId}`, data, { headers: headers })
            .then(function (response) {
                NotificationManager.success("Успешно променет продукт", "", 2000)
                handleClose();
                refreshData()


            })
            .catch(function (response) {
                NotificationManager.error("Настана грешкa", "", 2000)
            })


    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h2>Промени продукт</h2>
                <form noValidate autoComplete="off">
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Име на продукт"
                        name="productName"
                        value={productName}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Производител"
                        name="manufacturer"
                        value={manufacturer}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Добавувач"
                        name="supplier"
                        value={supplier}
                        onChange={handleChange}
                        disabled
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Бр. ф-ра"
                                name="numberDoc"
                                value={numberDoc}
                                onChange={handleChange}
                                sx={{ paddingTop: "15px" }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Количина"
                                name="quantity"
                                value={quantity}
                                onChange={handleChange}
                                sx={{ paddingTop: "15px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} className='time-inputs' id="calendar-schedule" >
                                <DatePicker
                                    label="Рок на употреба"
                                    ampm={false}
                                    value={new Date(date)}
                                    onChange={(event) => handleDateChange(new Date(event).toISOString())}
                                    renderInput={(params) => <TextField {...params} />}
                                    id="calendar-schedule"
                                    className='time-inputs'
                                /></LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button variant="contained" onClick={handleClose}>Откажи</Button>
                        <Button variant="contained" color="success" onClick={() => addNewProduct()} sx={{ ml: 2 }}>Зачувај</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default ProductEditing;
