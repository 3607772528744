import React, { useEffect, useState } from "react";
import { Box, Container, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import Chip from '@mui/material/Chip';


import 'dayjs/locale/de';

const ScheduleFilter = () => {

    const [doctors, setDoctors] = useState([])
    const [selectedDoctors, setSelectedDoctors] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())

    const handleFieldChange = (fieldName, value) => {
        setSelectedDoctors({ id: value.id, name: value.text });
    };
    const handleDateChange = (value) => {
        setSelectedDate(value)
    }

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;

        axios.get(baseUrl + '/accounts/get-doctors/')
            .then(function (response) {
                //console.log(response.data, headers)
                const arr_docs = []
                response.data.forEach(element => {
                    const obj_doc = {
                        id: element.id,
                        text: "д-р " + element.user.first_name,
                        color: element.color
                    }
                    arr_docs.push(obj_doc)
                });

                setDoctors(arr_docs)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });
    }, []);


    const handleClick = () => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        let data = { doctors: selectedDoctors, date: selectedDate }
        axios.post(baseUrl + "/schedules/generate-schedule/", data, { headers: headers })
            .then(function (response) {
                window.open(`${baseUrl}/${response.data.url}`, '_blank', 'noopener,noreferrer');

            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });
    }

    return (
        <div id="filters" >

            <Container sx={{ background: "#f3f3f3", marginTop: "3%", padding: "1%" }}>
                <h2 style={{ paddingLeft: "50px" }}>Филтер за распоред</h2>
                <Box sx={{ paddingLeft: { xs: "10px", sm: "30px", md: "50px" }, paddingRight: { xs: "10px", sm: "30px", md: "50px" }, mt: 2 }}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ width: '100%', height: 80, paddingBottom: 2 }} className='doctor-select'>
                                <InputLabel id="doctor-select-label">Доктор</InputLabel>
                                <Select
                                    labelId="doctor-select-label"
                                    id="demo-simple-select"
                                    value={''}
                                    label="Доктор"
                                    onChange={(event) => handleFieldChange('doctor', event.target.value)}
                                >
                                    {doctors.map((doctor, index) => (
                                        <MenuItem key={index} value={doctor}>
                                            {doctor.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ marginTop: { xs: "5%", sm: "0%", md: "0%" } }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} className='time-inputs' id="calendar-schedule">
                                <DatePicker
                                    label="Термин"
                                    ampm={false}
                                    value={selectedDate}
                                    onChange={(event) => handleDateChange(new Date(event).toISOString())}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Button
                                style={{
                                    width: '100%',
                                    height: 55,
                                    marginTop: "17px",
                                    backgroundColor: "#F4BFC5",
                                    color: "#ffffff"
                                }}
                                variant="standard"
                                onClick={() => handleClick()}
                            >
                                Генерирај распоред
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <br />
                <div>
                    <Chip
                        label={selectedDoctors.name || ""}
                        variant="outlined"
                    />
                </div>
            </Container>

        </div>
    )
}

export default ScheduleFilter