const doctors = [
    'д-р Антоанета',
    'д-р Марија',
    'д-р Виолета'
];

const createCalendatData = (data) =>{
    let returnArr = []
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        
        const dataObj = {
            "id":element.id,
            "startDate":element.created_for,
            "endDate":new Date(new Date(element.created_for).getTime() + element.time_slot_minutes*60000),
            "title": /*element.doctor.user.first_name + " <> " */ element.patient_name,
            "members":element.doctor.id,
            "status": element.status,
            "urgency":element.urgency,
            "phone_number":element.phone_number,
            "doctor": doctors[element.doctor.id]
        }
        const merge = {...element, ...dataObj}
        merge.created_by = merge.created_by.id
        returnArr.push(merge)

        
    }
    return returnArr
}

export default createCalendatData;