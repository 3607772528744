import './App.css';
import HomeComponents from './components/HomeComponent';
import LoginComponent from './components/LoginCompomnent';
import Logout from './components/Logout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyProfile from './components//Profile/MyProfile';
import ScheduleFilter from './components/FilterScheduling/FilterScheduling';
import StatsComponent from './components/Stats/StatsComponent';
import WarehouseList from './components/Warehouse/WarehouseList';
import ListingDailyOperations from './components/DailyOperation/ListingOperations';
import StatsDailyOperations from './components/StatsOperations/StatsDailyOperations';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import Navbar from './components/NavBar';
import Transactions from './components/Warehouse/Transactions';
import Hotjar from '@hotjar/browser';


const siteId = 5066509;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);


const router = createBrowserRouter([
	{
		path:"/",
		element: <Navbar/>,
		children:[
			{
				path: "/profile",
				element: <MyProfile />
			  },
			  {
				path: "/rasporedi",
				element: <ScheduleFilter />
			  },
			  {
				path: "/",
				element: <HomeComponents />
			  },
			  {
				path: "/magacin",
				element: <WarehouseList />
			  },
			  {
				path: "/dnevnik",
				element: <ListingDailyOperations />
			  },
			  {
				path: "/statistika-operacii",
				element: <StatsDailyOperations />
			  },
			  {
				path: "/transactions",
				element: <Transactions/>
			  }
		]
	},
	{
		path:"/login",
		element:<LoginComponent/>
	},
	{
		path: "/logout",
		element: <Logout />
	  },

])

function App() {

	
	return (
		<div >
      <RouterProvider router={router}/>
    </div>
	);
}

export default App;
