// useAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useAuth (){
  const history = useNavigate();

  useEffect(() => {
    // Check if a token exists in local storage
    const token = localStorage.getItem('key');

    // If there's no token, redirect to the login page
    if (!token) {
      history('/login/');
    }
  }, [history]);

  return;
};

export default useAuth;
