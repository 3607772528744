import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Container, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect } from "react";
import axios from "axios";
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';



const columns = [
   { field: 'id', headerName: 'Реден број', width: 90   },
   {
    field: "date_created",
    headerName: "Датум",
    type: "number",
    width: 180,
  },
  {
    
    field: "name",
    headerName: "Име на продуктот",
    width: 200,
    valueGetter: (value, row) => `${row.product.name}`
  },
  {
    field: "quantity",
    headerName: "Количина",
    description: "This column has a value getter and is not sortable.",
    sortable: true,
    width: 200,
 
  },
  

  {
    field: "log_type",
    headerName: "Тип на трансакцијата",
    width: 250,
    cellClassName: 'like-grid-button',
    renderCell: (params) => {
      return (
        <>
        {params.row.log_type === "влез" &&
          <Chip color="success" label={params.row.log_type} />
        }
         {params.row.log_type === "излез" &&
          <Chip color="error" label={params.row.log_type} />
        }
        {params.row.log_type === "промена на информации" &&
          <Chip color="primary" label={params.row.log_type} />
        }
        </>
       
      );
    }
  },

 
  
  
  
];




const Transactions = () => {
  const [product, setProduct] = useState("");
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [transactionsData, setTransactionsData] = useState([])


  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event);
  };

  const formatDate = (date) =>{
    let exp_date = new Date(date);
    let trans_date = `${exp_date.getFullYear()}-${exp_date.getMonth() + 1}-${exp_date.getDate()}`
    return trans_date 
  }

  useEffect(() => {
    let formatedStartDate = formatDate(startDate)
    let formatedEndDate = formatDate(endDate)
    const baseUrl = process.env.REACT_APP_API_URL;
    const headers = {
      'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
      'Content-Type': 'application/json', // Adjust content type as needed
  };
  let url = ""
  if (product !== "" ){
     url = baseUrl + `/warehouse/get-warehouse-logs/?date_created=${formatedStartDate},${formatedEndDate}&product_name=${product}`
  }
  else{
      url = baseUrl + `/warehouse/get-warehouse-logs/?date_created=${formatedStartDate},${formatedEndDate}`

  }
    axios.get(url, {headers:headers})
        .then(function (response) {
            setTransactionsData(response.data)
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Handle 401 error
                console.error("Unauthorized access - 401");
                window.location.href = "/login";
                // Perform any specific action such as redirecting to login
            } else {
                // Handle other errors
                console.error("An error occurred:", error);
            }
        });
}, [startDate, endDate, product]);

  return (
    <>
      <Container>
        <div
          style={{
            display: "flex",
            marginTop: "50px",
            justifyContent: "right",
            marginRight: "100px",
          }}
        >
          <TextField
      
            id="outlined-basic"
            label="Search products"
            variant="outlined"
            style={{ marginRight: "20px", marginTop: "15px" }}
            onChange={handleProductChange}
            

          />

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className="time-inputs"
            id="calendar-schedule"
          >
            <DatePicker
        label="Датум од"
        ampm={false}
        value={dayjs(startDate)}
        onChange={(newValue) => handleStartDateChange(newValue.toISOString())}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="DD/MM/YYYY"
        className="time-inputs"
      />
            <DatePicker
              label="Датум до"
              ampm={false}
              
              value={new Date(endDate)}
              onChange={(event) =>
                handleEndDateChange(new Date(event).toISOString())
              }
              renderInput={(params) => <TextField {...params} />}
              id="calendar-schedule"
              className="time-inputs"
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </div>

        <Grid container sx={{ marginTop: "50px" }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={transactionsData}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                }}
                pageSizeOptions={[5,10,20,50]}
                disableRowSelectionOnClick
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Transactions;
