import React, { useState } from "react";
import AppointmentCard from "./NextAppointmentCard";
import { useEffect } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';


const NextHourAppointments = () => {

  
    const [data, setData] = useState([])

    useEffect(() => {

		const baseUrl = process.env.REACT_APP_API_URL;
		const headers = {
			'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
			'Content-Type': 'application/json', // Adjust content type as needed
		};
		
		fetch(baseUrl + '/schedules/future-hours/', { headers })
			.then((response) => response.json())
			.then((data) => {
				setData(data)
			})

	}, []);

    const markContacted = (id) => {
		
		const apiUrl = process.env.REACT_APP_API_URL;

		fetch(apiUrl + '/schedules/future-hours/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Token ${localStorage.getItem("key")}`
			},
			body: JSON.stringify({id:id}),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data_res) => {
				const baseUrl = process.env.REACT_APP_API_URL;
					const headers = {
						'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
						'Content-Type': 'application/json', // Adjust content type as needed
					};
					
					fetch(baseUrl + '/schedules/future-hours/', { headers })
						.then((response) => response.json())
						.then((data) => {
							NotificationManager.success('Успешно контактиран',"", 3000);

							setData(data)
						})
					
					//navigate("/")
			})
			.catch((error) => {
				console.error('Error:', error);
				

			});
	}

    return (
        <div id="next-appointments">
            <h3>Термини за наредниот ден:</h3>
            {data.map((i) =>
                <AppointmentCard  key={i.id}
                    patient_name={i.patient_name}
                    start_time={i.created_for}
                    time_slot_minutes={i.time_slot_minutes}
                    phone_number={i.phone_number}
                    doctor = {i.doctor.user.first_name}
                    id = {i.id}
                    markContacted={markContacted}
                />
            )
            }
			<NotificationContainer/>
        </div>
    )
}

export default NextHourAppointments;