import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import axios from 'axios'

function QuantityAdding({ open, handleClose, productId, refreshData }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [productName, setProductName] = useState('');
    const [quantity, setQuantity] = useState("");


    const handleProductNameChange = (event) => {
        setProductName(event.target.value);
    };

    const handleQuantityHandler = (event) => {
        setQuantity(parseInt(event.target.value));
    }

    const SaveNewQuantityAdd = () => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        let data = { name: productName, quantity: quantity, id: productId }
        axios.post(baseUrl + `/warehouse/update-quantity/`, data, { headers: headers })
            .then(function (response) {
                NotificationManager.success("Успешно додаден продукт", "", 2000)
                refreshData()
                handleClose();
            })
            .catch(function (response) {
                NotificationManager.error("Настана грешкa", "", 2000)
            })



    }


    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        axios.get(baseUrl + `/warehouse/products/${productId}`, { headers: headers })
            .then(function (response) {
                console.log(response.data)
                setProductName(response.data[0].name)

            })

    }, [])



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h2>Додади количина</h2>
                <form noValidate autoComplete="off">
                    <TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label="Име на продукт"
                        name="productName"
                        value={productName}
                        onChange={(event) => handleProductNameChange(event)}

                    />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                type='number'
                                label="Количина"
                                name="quantity"
                                value={quantity}
                                onChange={(event) => handleQuantityHandler(event)}

                            />
                        </Grid>

                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button variant="contained" onClick={handleClose}>Откажи</Button>
                        <Button variant="contained" color="success" sx={{ ml: 2 }} onClick={() => SaveNewQuantityAdd()}>Зачувај</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default QuantityAdding;
