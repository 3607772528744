import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SmsIcon from '@mui/icons-material/Sms';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { styled } from '@mui/material/styles';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { NotificationContainer, NotificationManager } from 'react-notifications';


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};
const AppointmentCard = ({ patient_name, start_time, time_slot_minutes, phone_number, doctor, id, markContacted}) => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);


	const copySMSContent = () =>{
		navigator.clipboard.writeText(
			`Почитуван/а ${patient_name}, вашиот термин е во ${new Date(start_time).getHours()}:${new Date(start_time).getMinutes() === 0 ? "00" : new Date(start_time).getMinutes()}`

		)
		NotificationManager.success('', "Успешно копирано",1000);
	}
	
	
	return (
		<div>

			<Card sx={{
				maxWidth: 345,
				bgcolor: 'background.default',
				boxShadow: 1,
				borderRadius: 2,
				marginLeft: '5px',
				marginTop: '10px',
				p: 2,
				mb: 2
			}}>
				<CardContent>

					<Typography variant="subtitle3" display="block" color="text.primary">
						{patient_name}
					</Typography>
					<Typography variant="subtitle3" display="block" gutterBottom color="text.primary">
						д-р {doctor}
					</Typography>
					<Divider />
					<Typography variant="subtitle2" display="inline" gutterBottom color="text.secondary">
						{new Date(start_time).getHours()}:{new Date(start_time).getMinutes() == '0' ? "00" : new Date(start_time).getMinutes()}
					</Typography>
					<Typography variant="subtitle2" display="inline" gutterBottom color="text.secondary">
						-{new Date(new Date(start_time).getTime() + time_slot_minutes * 60000).getHours()}:{new Date(new Date(start_time).getTime() + time_slot_minutes * 60000).getMinutes() == "0" ? "00" : "30"}
					</Typography>
					<Typography variant="subtitle2" display="block" gutterBottom color="text.secondary">
						{phone_number}
					</Typography>


					<div className="icons-for-actions">
						<CheckOutlinedIcon sx={{ float: "right", fontSize: "30px" }} color="success"  onClick={()=> markContacted(id)}/>
						<TextsmsOutlinedIcon sx={{ float: "right", fontSize: "30px" }} onClick={handleOpen} />
					</div>


				</CardContent>
			</Card>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h4" component="h4">
						Порака
					</Typography>
					<Divider />
					<Typography id="modal-modal-title" variant="h6" component="h6">
						Телефонски број: {phone_number}
					</Typography>
					<Divider />
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Почитуван/а {patient_name}, вашиот термин е во {new Date(start_time).getHours()}:{new Date(start_time).getMinutes() === 0 ? "00" : new Date(start_time).getMinutes()}
					</Typography>
					<ContentCopyIcon sx={{float:'right'}} onClick={() => copySMSContent()}/>
				</Box>
			</Modal>
			<NotificationContainer/>
		</div>
	)
}

export default AppointmentCard;