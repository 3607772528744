import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Avatar, Button, Modal } from "@mui/material";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

const MyProfile = () => {
    const [userDetail, setUserDetail] = useState({ user: { first_name: "", profile_pricture: "" } })
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newPW, setPW] = useState("")
    const [newPW2, setPW2] = useState("")


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        fetch(baseUrl + '/accounts/details/', { headers })
            .then((response) => response.json())
            .then((data) => {
                setUserDetail(data)
            })
    }, []);


    const changePW = () => {
        const apiUrl = process.env.REACT_APP_API_URL;

        fetch(apiUrl + '/accounts/password/change/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem("key")}`
            },
            body: JSON.stringify({ new_password1: newPW, new_password2: newPW2 }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data_res) => {
                NotificationManager.success("Успешно променета лозинка", "", 3000);
                handleClose()
            })
            .catch((error) => {
                console.error('Error:', error);


            });
    }


    return (
        <div>

            <div>
                <Container sx={{ background: "#f3f3f3", marginTop: "5%", }}>
                    <Box sx={{ margin: "5% 0", paddingBottom: "40px" }}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={6} lg={5}>
                                <Avatar
                                    alt={userDetail.email}
                                    src={userDetail.profile_picture}
                                    sx={{ width: 250, height: 250, marginLeft: 7, justifyContent: "center" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={7}>
                                <Typography>Име:</Typography>
                                <TextField id="standard-basic" value={userDetail.user.first_name} variant="standard" disabled sx={{ width: "300px" }} />
                                <Typography>Емаил:</Typography>
                                <TextField id="outlined-basic" value={userDetail.email} variant="standard" disabled sx={{ width: "300px" }} />
                                <br></br><br></br>
                                <Button variant="contained" color="error" onClick={handleOpen}>
                                    Промени Лозинка
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>

                        <Typography>Напиши го твојот нов пасворд:</Typography>
                        <TextField id="standard-basic" variant="outlined" label="Пасворд" sx={{ width: "300px" }} type="password"
                            onChange={(e) => setPW(e.target.value)} />
                        <Typography>Повтори го твојот пасворд:</Typography>
                        <TextField id="standard-basic" variant="outlined" label="Пасворд" sx={{ width: "300px" }} type="password"
                            onChange={(e) => setPW2(e.target.value)} />
                        <br /><br />
                        <Button variant="contained" color="success" onClick={changePW}>
                            Промени Лозинка
                        </Button>
                    </Box>
                </Modal>
            </div>
            <NotificationContainer />
        </div>
    )
}


export default MyProfile