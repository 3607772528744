import React, { useEffect, useState } from "react";
import useAuth from '../utils/authentication'
import Navbar from "./NavBar";
import MainCalendar from "./MainCalendar";
import { Button } from "@mui/material";
import '../static/Main.css'
import AddNewAppointment from "./AddNewAppointment";
import DoctorsCheckboxComponent from "./DoctorsComponent";
import DailySchedule from "./DailySchedule";
import Grid from '@mui/material/Grid';
import axios from "axios";


const HomeComponents = () => {
    const [doctors, setDoctors] = useState([])


    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        axios.get(baseUrl + '/accounts/get-doctors/')
            .then(function (response) {
                //console.log(response.data, headers)
                const arr_docs = []
                response.data.forEach(element => {
                    const obj_doc = {
                        id: element.id,
                        text: "д-р " + element.user.first_name,
                        color: element.color
                    }
                    arr_docs.push(obj_doc)
                });
                setDoctors(arr_docs)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });
    }, []);

    useAuth();

    return (
        <div>
            <div id="main">
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        {/*<AddNewAppointment />*/}
                        <MainCalendar />
                        {/* <DoctorsCheckboxComponent />*/}
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <DailySchedule doctors={doctors} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default HomeComponents;