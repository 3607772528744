import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Box, Typography, Paper } from '@mui/material';

const StatsCard = (statsData) => {

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <BarChart
          width={500}
          height={300}
          data={statsData.statsData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {console.log(statsData)}
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </Paper>
    </Box>
  );
};

export default StatsCard;
