import React from 'react';
import {
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextareaAutosize,

} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Grid from '@mui/material/Grid';
import 'dayjs/locale/de';



// Example options for select fields
const doctors = [
    ['д-р Антоанета', 5],
    ['д-р Марија', 6],
    ['д-р Виолета', 7]
];
const timeSlots = [30, 60, 90, 120];
const urgencies = ['Normal', 'Urgent', 'Critical'];

const operations = [
    "Прв пат",
    "Приватен",
    "Екстракција",
    "Екстракција млечен заб",
    "Препарација",
    "Демонтирање",
    "Консултација",

]




const CustomLayout = ({ visible,
    visibleChange,
    appointmentData,
    onFieldChange,
    cancelAppointment,
    commitAppointment,
    onClose, onSave,
    props,
    ...restProps

}) => {
    const [formData, setFormData] = React.useState({ ...appointmentData });

    React.useEffect(() => {
        setFormData({ ...appointmentData });
    }, [appointmentData]);

    const handleFieldChange = (fieldName, value) => {
        setFormData({ ...formData, [fieldName]: value });
        onFieldChange({ ...formData, [fieldName]: value });
        //console.log(formData)
    };



    return (
        <div style={{ background: 'white', padding: '5px', }}>
            <Grid container>

                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        value={formData.patient_name || ''}
                        onChange={(event) => handleFieldChange('patient_name', event.target.value)}
                        fullWidth
                        margin="normal"
                        label="Име на Пациент"
                        className='doctor-inputs'
                        name='patient_name'

                    />
                    <TextField
                        variant="outlined"
                        value={formData.phone_number || ''}
                        onChange={(event) => handleFieldChange('phone_number', event.target.value)}
                        fullWidth
                        margin="normal"
                        label="Телефонски број"
                        className='doctor-inputs'
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} className='time-inputs'>
                        <DateTimePicker
                            label="Термин"
                            ampm={false}
                            value={formData.created_for || formData.startDate}
                            onChange={(event) => handleFieldChange('created_for', new Date(event).toISOString())}
                            renderInput={(params) => <TextField {...params} />}
                        /></LocalizationProvider>

                    <TextField
                        variant="outlined"
                        value={formData.teeth_num || 1}
                        onChange={(event) => handleFieldChange('teeth_num', event.target.value)}
                        fullWidth
                        margin="normal"
                        label="Број на заби за интервенција"
                        className='doctor-inputs'
                        type='number'
                    />

                </Grid>





                <Grid item xs={6}>
                    <FormControl fullWidth className='doctor-select'>
                        <InputLabel id="doctor-select-label">Доктор</InputLabel>
                        <Select
                            labelId="doctor-select-label"
                            id="demo-simple-select"
                            value={formData.doctor || ''}
                            label="Доктор"
                            onChange={(event) => handleFieldChange('doctor', event.target.value)}


                        >
                            {restProps.resources[0].instances.map((doctor, index) => (
                                <MenuItem key={index} value={doctor.id}>
                                    {doctor.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl><br></br>
                    <FormControl fullWidth className='doctor-select'>
                        <InputLabel id="urgency-select-label">Итност</InputLabel>
                        <Select
                            labelId="urgencys-select-label"
                            id="demo-simple-select"
                            value={formData.urgency || ''}
                            label="Итност"
                            onChange={(event) => handleFieldChange('urgency', event.target.value)}

                        >
                            {urgencies.map((doctor, index) => (
                                <MenuItem key={index} value={doctor}>
                                    {doctor}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl><br></br>



                    <FormControl fullWidth className='doctor-select' required>
                        <InputLabel id="timeSlot-select-label">Времетраење</InputLabel>
                        <Select
                            labelId="timeSlot-select-label"
                            id="demo-simple-select"
                            value={formData.time_slot || '30'}
                            label="Времетраење"
                            onChange={(event) => handleFieldChange('time_slot_minutes', event.target.value)}
                            required

                        >
                            {timeSlots.map((doctor, index) => (
                                <MenuItem key={index} value={doctor}>
                                    {doctor}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth className='doctor-select' required>
                        <InputLabel id="operation-select-label">Интервенција</InputLabel>
                        <Select
                            labelId="operation-select-label"
                            id="operation-select"
                            value={formData.operation || ""}
                            label="Интервенција"
                            onChange={(event) => handleFieldChange('operation', event.target.value)}
                            required

                        >
                            {operations.map((doctor, index) => (
                                <MenuItem key={index} value={doctor}>
                                    {doctor}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br></br>
                </Grid>
            </Grid>
            <TextareaAutosize minRows={10} className='schedule-notes' placeholder="Забелешки"
                onChange={(event) => handleFieldChange('notes', event.target.value)}
            />
        </div>
    );
};


export default CustomLayout
