import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../static/Calendars.css';
import { useDispatch } from 'react-redux';
import { setNewDate } from '../redux/dateReducer';
import 'dayjs/locale/de';
import NextHourAppointments from './NextHourAppointments';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';


export default function DateCalendarValue() {
  const [value, setValue] = React.useState(dayjs(Date()));
  const dispatch = useDispatch();
  //const counter = useSelector((state) => state.date.value);



  const handleDayClick = (newValue) => {
    setValue(newValue)
    dispatch(setNewDate(new Date(newValue).toISOString()));
    //console.log(dayjs(newValue).toISOString())
  }

  useEffect(() => {
    //console.log(value); // This will log the updated value every time it changes

  }, [value]);

  return (

    <div id="month-calendar">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="mk">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={value}
          onChange={(newValue) => {
            handleDayClick(newValue);
          }}
          className='calendar-component'
        />
      </LocalizationProvider>
<NextHourAppointments/>
    </div>
  );
}