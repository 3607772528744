import React, { useState } from "react";

import transparent from '../static/transparent_logo.png'
import '../static/LoginComponents.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';







function LoginComponent() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [response, setResponse] = useState(null);
    const navigate = useNavigate();

    const handleLoginClick = () =>{
        const apiUrl = process.env.REACT_APP_API_URL;
        

        const data = {
            email: email,
            password: password,
            
          };
      
          // Send the POST request using the fetch API
          fetch(apiUrl + '/accounts/login/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => {if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();})
            .then((data) => {
              localStorage.setItem("key", data.key)
              NotificationManager.success('You Logged in', '', 3000);
             navigate("/")
            })
            .catch((error) => {
              console.error('Error:', error);
              NotificationManager.error('Unable to login with these credentials', '', 2000);

            });
        };
    
    return (
        <div className="login-page">
            <div className="login-logo">
                <img src={transparent} alt="Logo"></img>
            </div>
            <div className="login-form">
                <div className="login-inputs">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Email" variant="outlined" className="login-input" onChange={(e) => setEmail(e.target.value)}/><br></br>
                        <TextField id="outlined-basic" label="Password" variant="outlined"  type="password" className="login-input" onChange={(e) => setPassword(e.target.value)}/><br/>
                        <Button variant="contained" className="login-btn" onClick={handleLoginClick}>Login</Button>
                        
                    </Box>
                    <NotificationContainer/>
                </div>
            </div>
        </div>
    )
}

export default LoginComponent