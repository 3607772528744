import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import {
	Scheduler,
	WeekView,
	Toolbar,
	Appointments,
	DayView,
	Resources,
	AppointmentTooltip,
	AppointmentForm,
	EditRecurrenceMenu,
	CurrentTimeIndicator

} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
//import { appointments } from '../../../demo-data/appointments';
import createCalendatData from '../utils/createCalendarObjects';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import CustomLayout from './CustomForm';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useMediaQuery } from 'react-responsive';





const Demo = (props) => {
	const [data, setData] = useState([]);
	const [location, setLocation] = useState("vinica")
	const reduxDate = useSelector((state) => state.date.value);
	const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });


	const resources = [

		{
			fieldName: 'members',
			title: 'Members',

			instances: props.doctors
		}
	]

	useEffect(() => {

		const baseUrl = process.env.REACT_APP_API_URL;
		const headers = {
			'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
			'Content-Type': 'application/json', // Adjust content type as needed
		};
		console.log(reduxDate)
		const justDate = reduxDate.split("T")[0]
		fetch(baseUrl + '/schedules/appointments/?date=' + justDate + "&location=" + location, { headers })
			.then((response) => response.json())
			.then((data) => {
				const res = createCalendatData(data)
				console.log(res)
				setData(res)
			})

	}, [reduxDate, location]);

	

	const handleChangeLocation = (event) => {
		setLocation(event.target.value);
	};

	const commitChanges = ({ added, changed, deleted }) => {
		const apiUrl = process.env.REACT_APP_API_URL;
		if (added) {
			if (!added.created_for) {
				added.created_for = added.startDate
			}
			if (!added.time_slot_minutes) {
				added.time_slot_minutes = 30
			}
			added.location = location
			fetch(apiUrl + '/schedules/appointments/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Token ${localStorage.getItem("key")}`
				},
				body: JSON.stringify(added),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then((data_res) => {
					const res = createCalendatData([data_res,])
					setData(data.concat(res));
					console.log(res)
					NotificationManager.success('Успешно креирано', "", 3000);
					//navigate("/")
				})
				.catch((error) => {
					console.error('Error:', error);
					NotificationManager.error('Не може да се закаже тој термин', '', 2000);

				});
		}

		if (changed) {
			console.log(changed)
			const id = Object.keys(changed)
			const data_for_update = changed[id]
			if (!data_for_update.created_for) {
				data_for_update.created_for = data_for_update.startDate
			}
			if (!data_for_update.time_slot_minutes) {
				data_for_update.time_slot_minutes = 30
			}
			fetch(apiUrl + '/schedules/appointments/', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Token ${localStorage.getItem("key")}`
				},
				body: JSON.stringify(data_for_update),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then((data_res) => {
					const baseUrl = process.env.REACT_APP_API_URL;
					const headers = {
						'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
						'Content-Type': 'application/json', // Adjust content type as needed
					};
					const justDate = reduxDate.split("T")[0]
					fetch(baseUrl + '/schedules/appointments/?date=' + justDate, { headers })
						.then((response) => response.json())
						.then((data) => {
							const res = createCalendatData(data)
							setData(res)
						})
					NotificationManager.success('Успешно променето', "", 3000);
					//navigate("/")
				})
				.catch((error) => {
					console.error('Error:', error);
					NotificationManager.error('Unable to login with these credentials', '', 2000);

				});
		}
		if (deleted) {
			console.log(deleted)
			fetch(apiUrl + '/schedules/appointments/', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Token ${localStorage.getItem("key")}`
				},
				body: JSON.stringify({ id: deleted }),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then((data_res) => {
					const baseUrl = process.env.REACT_APP_API_URL;
					const headers = {
						'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
						'Content-Type': 'application/json', // Adjust content type as needed
					};
					const justDate = reduxDate.split("T")[0]
					fetch(baseUrl + '/schedules/appointments/?date=' + justDate, { headers })
						.then((response) => response.json())
						.then((data) => {
							const res = createCalendatData(data)
							setData(res)
						})
					NotificationManager.success('Успешно избришано', "", 3000);
				})
				.catch((error) => {
					console.error('Error:', error);
					NotificationManager.error('Не може да се закаже тој термин', '', 2000);
				});
		}
	};


	return (
		<Paper>
			<div className='location-choice' style={{ float: "right" }}>
				<InputLabel id="demo-simple-select-label" >Локација</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={location}
					label="Локација"
					onChange={handleChangeLocation}
					style={{ width: "200px" }}
				>
					<MenuItem value={"vinica"}>Виница</MenuItem>
					<MenuItem value={"skopje"}>Скопје</MenuItem>

				</Select>

			</div>
			<Scheduler data={data} height={1200}>
				<ViewState currentDate={reduxDate} onCurrentDateChange={reduxDate} />
				<EditingState
					onCommitChanges={commitChanges}
				/>
				<EditRecurrenceMenu />
				{isSmallScreen ?
					<DayView startDayHour={8.5} endDayHour={20.5} excludedDays={[0]} /> :
					<WeekView startDayHour={8.5} endDayHour={20.5} />}
				<Toolbar />
				<Appointments />
				<AppointmentTooltip
					showOpenButton
				/>
				<AppointmentForm
					basicLayoutComponent={CustomLayout}
					onAppointmentDataChange={commitChanges}
					doctors={props.doctors}
				/>
				<Resources
					data={resources}
				/>
				<CurrentTimeIndicator
					shadePreviousCells={true}
					shadePreviousAppointments={true} />
			</Scheduler>
			<NotificationContainer />
		</Paper>
	);
};

export default Demo;
