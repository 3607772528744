import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import ProductAdding from "./ProductsAdding";
import ProductEditing from "./ProductsEditing";
import QuantityAdding from "./QuantityAdding"; //this
import QuantityMin from "./QuantityMin";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  useMediaQuery, useTheme
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";


const WarehouseList = () => {
  const [warehouseData, setWarehousData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [productForEdit, setProductForEdit] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [openAddQuantity, setAddQuantity] = useState(false);
  const [openMinQuantity, setMinQuantity] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
      "Content-Type": "application/json", // Adjust content type as needed
    };
    axios
      .get(baseUrl + "/warehouse/products/", { headers: headers })
      .then(function (response) {
        setWarehousData(response.data);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          // Handle 401 error
          console.error("Unauthorized access - 401");
          window.location.href = "/login";
          // Perform any specific action such as redirecting to login
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  }, []);

  const refreshData = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
      "Content-Type": "application/json", // Adjust content type as needed
    };
    axios
      .get(baseUrl + "/warehouse/products/", { headers: headers })
      .then(function (response) {
        setWarehousData(response.data);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          // Handle 401 error
          console.error("Unauthorized access - 401");
          window.location.href = "/login";
          // Perform any specific action such as redirecting to login
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };

  const handleSearchProduct = (search_term) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
      "Content-Type": "application/json", // Adjust content type as needed
    };
    axios
      .get(baseUrl + `/warehouse/products/?product_name=${search_term}`, {
        headers: headers,
      })
      .then(function (response) {
        setWarehousData(response.data);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          // Handle 401 error
          console.error("Unauthorized access - 401");
          window.location.href = "/login";
          // Perform any specific action such as redirecting to login
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };

  // edit modal
  const handleOpenEdit = (productId) => {
    setProductForEdit(productId);
    setOpenEdit(true);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  // add quantity modal
  const handleAddQuantityEdit = (productId) => {
    setProductForEdit(productId);
    setAddQuantity(true);
  };
  const handleCloseAddQuantity = () => setAddQuantity(false);

  // min quantity modal
  const handleMinQuantityEdit = (productId) => {
    setProductForEdit(productId);
    setMinQuantity(true);
  };
  const handleCloseMinQuantity = () => setMinQuantity(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  
  const handleSubmitPWD = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const data = {
      email: email,
      password: password,
    };
    axios
      .post(`${apiUrl}/accounts/login/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        localStorage.setItem("key", response.data.key);
        NotificationManager.success("Потврдивте", "", 3000);
        setIsAuthenticated(true);
      });
  };

  const columns = [
    //{ field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Име на продукт", width: 180 },
    { field: "quantity", headerName: "Количина", width: 145 },
    { field: "expiary_date", headerName: "Рок на траење ", width: 165  },
    { field:"invoice_number", headerName: "Бр. на фактура", width:145, valueGetter: (value, row) => `${row.invoice_number.invoice_number}`},
    { field:"invoice_company", headerName: "Добавувач", width:145, valueGetter: (value, row) => `${row.invoice_number.company_name}`},

    {
      field: "Edit",
      headerName: "Измени",
      width: 145,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <Button
              onClick={() => handleOpenEdit(params.id)}
              variant="contained"
              color="secondary"
            >
              <EditIcon />
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "addQuantity",
      headerName: "Влез",
      width: 145,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <Button
              onClick={() => handleAddQuantityEdit(params.id)}
              variant="contained"
              color="success"
            >
              <AddIcon />
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "substracQuantity",
      headerName: "Излез",
      width: 145,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <Button
              onClick={() => handleMinQuantityEdit(params.id)}
              variant="contained"
              color="error"
            >
              <RemoveIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container sx={{ marginTop: "50px" }}>
      <Grid item lg={1}></Grid>
      <Grid item xs={12} md={12} lg={10}>
        {isAuthenticated && (
          <div id="main">
            <Box
              sx={{
				        display: isMobile ? 'block' : 'flex',        
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                margin: "auto",
              }}
            >
              <Box style={{}}>
                <div className="add-button" style={{ width: "300px" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleOpen}
                  >
                    Додади нов продукт
                  </Button>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Button variant="contained" color="primary">
                    <Link
                      to={"/transactions"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Прикажи влез / излез
                    </Link>
                  </Button>
                </div>
              </Box>

              <Box sx={{ display: "flex", borderBottom: "2px solid black" }}>
                <Box>
                  <SearchIcon sx={{ paddingTop: "20px" }} />
                </Box>
                <Box
                  className="search-input"
                  style={{ width: "180px", textAlign: "center" }}
                >
                  <TextField
                    id="standard-multiline-flexible"
                    label="Име на продуктот"
                    multiline
                    // maxRows={4}
                    variant="standard"
                    onChange={(event) =>
                      handleSearchProduct(event.target.value)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <ProductAdding
              open={open}
              handleClose={handleClose}
              refreshData={refreshData}
            />

            {openEdit && (
              <ProductEditing
                open={openEdit}
                handleClose={handleCloseEdit}
                productId={productForEdit}
                refreshData={refreshData}
              />
            )}

            {openAddQuantity && (
              <QuantityAdding
                open={openAddQuantity}
                handleClose={handleCloseAddQuantity}
                productId={productForEdit}
                refreshData={refreshData}
              />
            )}

            {openMinQuantity && (
              <QuantityMin
                open={openMinQuantity}
                handleClose={handleCloseMinQuantity}
                productId={productForEdit}
                refreshData={refreshData}
              />
            )}

            <div style={{ marginTop: "20px" }}>
              <DataGrid
                rows={warehouseData}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </div>
          </div>
        )}
        <NotificationContainer />

        <Dialog open={!isAuthenticated} disableEscapeKeyDown>
          <DialogTitle>PASSWORD ПОТРЕБЕН !!!!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Внесете го вашиот пасворд за да продолжите
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="emailInput"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              margin="dense"
              id="passwordInput"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={handlePasswordChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitPWD} color="primary">
              Потврди
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default WarehouseList;
