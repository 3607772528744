import React from "react";
import '../static/Main.css'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';



function DoctorsCheckboxComponent() {



    return (
        <div className="list-doctors-box">
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Д-р Антоанета" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Д-р Марија" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Д-р Виолета" />
            </FormGroup>
        </div>
    )

}

export default DoctorsCheckboxComponent;